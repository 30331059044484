import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Weighted Strict Pullups 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`5-Bar Muscle Ups`}</p>
    <p>{`10-HSPU’s`}</p>
    <p>{`4-Bar Muscle Ups`}</p>
    <p>{`8-HSPU’s`}</p>
    <p>{`3-Bar Muscle Ups`}</p>
    <p>{`6-HSPU’s`}</p>
    <p>{`2-Bar Muscle Ups`}</p>
    <p>{`4-HSPU’s`}</p>
    <p>{`1-Bar Muscle Up`}</p>
    <p>{`2-HSPU’s`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`10-Ball Slams (30/20)`}</p>
    <p>{`40-DU’s`}</p>
    <p>{`8-Ball Slams`}</p>
    <p>{`30-DU’s`}</p>
    <p>{`6-Ball Slams`}</p>
    <p>{`20-DU’s`}</p>
    <p>{`4-Ball Slams`}</p>
    <p>{`10-DU’s`}</p>
    <p>{`2-Ball Slams`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free CrossFit class at 9:00 and 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`November 17th-18th CrossFit the Ville will be hosting a USAW
Weightlifting Level 1 Coaching Certification Course!  The Scope and
Sequence of this course includes the teaching progressions of the
Snatch, Clean & Jerk, and all associated movements.  Participants will
gain knowledge and experience of programming of training for both
competitive weightlifters and for athletes using the weightlifting
movements for strength and power development as it applies to their
chosen sport.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Lead Instructor: Mark Cannella, President and Head Coach of Columbus
Weightlifting in Columbus, Ohio.  He has been part of the lifting
community as an athlete, coach, administrator, and official for over 29
years.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This 2 day course is \\$499.  Those who complete this course will be
awarded their USAW Level 1 Coaching Certificate.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more information contact Daniel\\@crossfittheville.org or`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Mark Cannella at mark\\@columbusweightlifting.org.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      